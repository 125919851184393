import MessageMap from '../i18n.d';

const esES: MessageMap = {
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em inglês'
  }
};

export default esES;
