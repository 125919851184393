import { LoadingDots } from '@hexa-ui/components';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const ExamplePage = lazy(() => import('./pages/ExamplePage/ExamplePage'));

const PARENT_BASEPATH = '/membership';
const BASEPATH = `${PARENT_BASEPATH}/rules-v2`;

export function RouterContent() {
  return (
    <Routes>
      <Route
        path={BASEPATH}
        index
        element={
          <Suspense fallback={<LoadingDots />}>
            <ExamplePage />
          </Suspense>
        }
      />
    </Routes>
  );
}

function Router() {
  return (
    <BrowserRouter>
      <RouterContent />
    </BrowserRouter>
  );
}

export default Router;
